namespace eh {
    /** End-/Footnote : collect endnotes on page and and insert into container */
    export class Footnotes {
        static instances :Footnotes[]  = [] 
        static ehInit($base: JQuery<HTMLElement>, isSnippetRequest: boolean): void {
          if(!isSnippetRequest) {
              $(`.${ Footnotes.FOOTNOTES_WRAPPER_CLASSNAME }`, $base).each((index:number, element:HTMLElement) => {
                  this.instances.push(new Footnotes(element, Footnotes.instances.length));
              });    
            } else {
              this.instances.forEach(enotes => {
                enotes.updateForm($base);
              });
            }
        }

        /* module bound */
        public static FOOTNOTES_WRAPPER_CLASSNAME: string = 'marker-footnotes-wrapper';
        /* parent of collected endnotes */
        public static FOOTNOTES_CONTAINER_CLASSNAME: string = 'marker-footnotes-container';
        /* parent of endnote instance  */
        public static FOOTNOTE_INSTANCE_CLASSNAME: string = 'marker-footnote';
        public static FOOTNOTE_INSTANCE_LINK_CLASSNAME: string = 'marker-footnote-link';
        public static FOOTNOTE_INSTANCE_CONTENT_CLASSNAME: string = 'marker-footnote-content';

        private container: HTMLElement | null | undefined;
        private index: number = 0;
        private headerHeight: number = 0;

        private vm: Footnotes;

        constructor(
            private readonly base: HTMLElement,
            private readonly instanceId: number
        ) {
            this.container = this.base.querySelector(`.${Footnotes.FOOTNOTES_CONTAINER_CLASSNAME}`) as HTMLElement;
            this.updateForm($(document.body));
            $(':root').on(HEADER_EVENTS.DOM_MUTATION, this.headerChanged);
        }

        private updateForm(elem: JQuery<HTMLElement>):void {
          if(this.container) {
            elem.find(`.${Footnotes.FOOTNOTE_INSTANCE_CLASSNAME}`).each((index:number, note:HTMLElement) => {
              this.insertNote($(note));
            });
            if(this.index && this.base.classList.contains('ehtw-hidden')) {
              this.base.classList.remove('ehtw-hidden');
            }
          }
        }


        private insertNote(note: JQuery<HTMLElement>):void {
          let noteLink = note.find(`.${Footnotes.FOOTNOTE_INSTANCE_LINK_CLASSNAME}:first`);
          if(noteLink.length) {
            const key: String = this.nextKey();
            noteLink.attr('href', '#'+key).text(this.index);
            noteLink[0].addEventListener('click', this.onClickScrollTo);
            let nx = jQuery('<li>', {
              id: key
            });
            nx.html(noteLink.data('noteContent'));
            eh.LinkTarget.init(nx);
            eh.Overlay.registerLinks(nx);
            this.container?.appendChild(nx[0]);
          }
        }

        private nextKey():String {
          return ((this.instanceId > 0)?(''+this.instanceId):'')+"footnote_"+(++this.index);
        }

        private onClickScrollTo = (e: UIEvent): void => {
          const $target = $(e.currentTarget as HTMLElement);
          const linkTarget = $target.attr('href');
          if (linkTarget && linkTarget.startsWith('#')) {
            let t = this.container?.offsetTop || 0;
            e.preventDefault();
            eh.ScrollPage.scrollToAnimated(t);
          }

        }

        private headerChanged: (e: JQuery.TriggeredEvent, metrics: IHeaderMetrics) => void = (e: JQuery.TriggeredEvent, metrics: IHeaderMetrics): void => {
          this.headerHeight = metrics.visibleHeaderHeight + metrics.visibleDisplaceElemHeight || this.headerHeight;
        }

    }
}