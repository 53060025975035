namespace eh {

  export class EhFilterController {

    static ehInit($base: JQuery<HTMLElement>, isSnippetRequest: boolean): void {
      $(`.${EhFilterController.FILTER_WRAPPER}`, $base).each((index: number, element: HTMLElement) => {
        new EhFilterController(element);
      });
    }

    public static FILTER_WRAPPER: string = 'ehel-product-finder';
    public static FILTER_WRAPPER_CLASSNAME: string = 'ehel-filter-wrapper'; // wrapper filter elem ui
    public static FILTER_CLASSNAME: string = 'ehel-filter--filter'; // control open filter elem list
    
    public static FILTER_SELECTION_LIST_CLASSNAME: string = 'ehel-filter--filters-selection-list'; // filter elem values list
    public static FILTER_SELECTED_CLASSNAME: string = 'ehel-product-finder--filters-selected'; // selected filter values pills
    public static FILTER_REMOVE_SINGLE_CLASSNAME: string = 'ehel-filter-filters-remove-single';// control selected filter value remove

    public static FILTER_SELECTION_CLASSNAME: string = 'ehel-filter--filters-selection';// wrapper sidebar
    public static FILTER_SELECTION_GROUP_CLASSNAME: string = 'ehel-product-finder--filter-group'; // sidebar wrapper filters
    public static FILTER_CONTROL_CLONE: string = 'ehts-cbx-clone';// checkboxes in sidepanel
    public static FILTER_SELECTION_LIST_LOADING_CLASSNAME: string = 'ehel-filter--filters-selection-list-loading';// sidebar filter elem loading cover

    public static FILTER_RESULTS_MENU_CLASSNAME: string = 'ehel-product-finder--results-menu'; // wrapper filter elems
    public static FILTER_CATEGORY_ITEM_CLASSNAME: string = 'ehel-filter--heading eh-accordion-ctrl--category-heading';//'eh-accordion-ctrl--category-item';

    public static FILTER_FILTERS_OPEN_CLASSNAME: string = 'ehel-filter--menu-open'; // open sidebar
    public static FILTER_FILTERS_CLOSE_CLASSNAME: string = 'ehel-filter--menu-close'; // sidebar close
    private static FILTER_SELECTED_CLEAR_BTN_CLASSNAME: string = 'ehel-product-finder-trigger-form-reset';//'ehel-product-finder--filters-selected-clear-btn';

    public static SPINNER_CLASSNAMES: string = 'icon-ehel-spinner ehtw-animate-spin';
    public static REMOVE_CLASSNAMES: string = 'icon-ehel-cancel';
    public static ARROW_DOWN_CLASSNAMES: string ='icon-ehel-arrow-down';
    public static ARROW_UP_CLASSNAMES: string ='icon-ehel-arrow-up';

    public static ICON_CLASSNAME: string = 'ehel-icon';
    public static STICKY_CLASSNAME: string = 'is-sticky';
    
    private el: HTMLElement | null | undefined;
    private readonly form: HTMLFormElement | null | undefined;// fix
    private filters: NodeListOf<HTMLElement> | null | undefined;
    private filtersLoading: NodeListOf<HTMLElement> | null | undefined;
    private filtersSelected: HTMLElement | null | undefined;
    private navigation: HTMLElement | null | undefined;
    private inputs: NodeListOf<HTMLInputElement> | null | undefined;
    private activeFiltersButtons: NodeListOf<HTMLElement> | null | undefined;
    private openButtons: NodeListOf<HTMLElement> | null | undefined;
    private closeButtons: NodeListOf<HTMLElement> | null | undefined;
    private clearButton: NodeListOf<HTMLElement> | null | undefined;
    private submitButtons: NodeListOf<HTMLElement> | null | undefined;
    private menu: HTMLElement | null | undefined;
    private menuGroup: HTMLElement | null | undefined;
    private resultsMenu: HTMLElement | null | undefined;
    private vm: EhFilterViewModel | null | undefined = null;
    private root: HTMLElement = document.scrollingElement as HTMLElement;

    constructor(private readonly base: HTMLElement | null) {
      this.el = this.base;
      this.form = this.el?.querySelector("form");
      if(!this.initDynamics()) {
        return;
      }
      this.init();
      window.addEventListener('resize', this.onWindowResized);
      window.addEventListener('scroll', this.onWindowScroll);
    }

    private initDynamics():boolean {
      this.menu = this.el?.querySelector(`.${EhFilterController.FILTER_SELECTION_CLASSNAME}`);
      this.closeButtons = this.el?.querySelectorAll(`.${EhFilterController.FILTER_FILTERS_CLOSE_CLASSNAME}`);
      this.resultsMenu = this.el?.querySelector(`.${EhFilterController.FILTER_RESULTS_MENU_CLASSNAME}`);
      this.menuGroup = this.menu?.querySelector(`.${EhFilterController.FILTER_SELECTION_GROUP_CLASSNAME}`);
      this.filters = this.el?.querySelectorAll(`.${EhFilterController.FILTER_CLASSNAME}`);
      this.filtersSelected = this.el?.querySelector(`.${EhFilterController.FILTER_SELECTED_CLASSNAME}`);
      // page, filters bar, filter, cover checkboxes
      this.filtersLoading = this.el?.querySelectorAll(`.${EhFilterController.FILTER_SELECTION_LIST_LOADING_CLASSNAME}`)
      this.openButtons = this.el?.querySelectorAll(`.${EhFilterController.FILTER_FILTERS_OPEN_CLASSNAME}`);
      this.activeFiltersButtons = this.el?.querySelectorAll(`.${EhFilterController.FILTER_REMOVE_SINGLE_CLASSNAME}`);
      this.inputs = this.el?.querySelectorAll(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME} input`);
      this.clearButton = this.el?.querySelectorAll(`.${EhFilterController.FILTER_SELECTED_CLEAR_BTN_CLASSNAME}`);
      this.submitButtons = this.el?.querySelectorAll('.ehts-filter-menu-close');
      if (!this.el || !this.filters || !this.openButtons || !this.closeButtons || !this.menu || !this.resultsMenu || !this.menuGroup) {
        return false;
      }

      this.vm = new EhFilterViewModel(
        this.el,
        this.filters,
        this.menu,
        this.menuGroup,
        this.resultsMenu
      );
      this.registerDynamicControls();
      return true;
    }

    private init(): void {
      this.root.addEventListener('click', this.onScrollPageClicked);
    }

    private registerDynamicControls(): void {
      this.openButtons?.forEach(btn => {
        btn.addEventListener('click', this.onOpenMenuClick);
      });
      this.closeButtons?.forEach(btn => {
        btn.addEventListener('click', this.onCloseMenuClick);
      });
      this.activeFiltersButtons?.forEach(btn => {
          btn.addEventListener('click', this.removeFilter);
      })
      this.filters?.forEach(filter => {
          // console.log("adding  filter handler to:", filter);
          filter.addEventListener('click', this.doFilterClick);
      });
      this.inputs?.forEach(input => {
        if(!input.classList.contains(EhFilterController.FILTER_CONTROL_CLONE)) {
          input.addEventListener('change', this.onChange);
        } else {
          input.addEventListener('change', (e: UIEvent): void => {
            let cbc = $(input);
            let idd = cbc.data('origId') || '';
            if(idd) {
              let chb = $(':root').find('input#'+idd);
              if(chb) {
                chb.prop('checked', !chb.prop('checked'));
                this.onChange(e, true);
              }
            }
          });
        }
      });

      this.menuGroup?.addEventListener('scroll', this.onMenuGroupScroll);

      this.menuGroup?.querySelectorAll(`.${EhFilterController.FILTER_CATEGORY_ITEM_CLASSNAME}`)?.forEach(cat => {
        cat.addEventListener('click', this.onMenuGroupScroll);
      });
      this.filtersLoading?.forEach(filter => {
        filter.addEventListener('click', this.doFilterLoadingClick);
      });  
      this.clearButton?.forEach(button => button.addEventListener('click', this.resetForm));
      this.submitButtons?.forEach(x => x.addEventListener('click', this.onCloseMenuClick));
    }

    private removeFilter = (e: UIEvent): void => {
      let button = e.currentTarget as HTMLElement;
      e.preventDefault();
      e.stopPropagation();
      button.blur();
      $(button).find("span.eh-icon").removeClass(EhFilterController.REMOVE_CLASSNAMES).addClass(EhFilterController.SPINNER_CLASSNAMES);
      const paramName = button.dataset.paramName
      const paramValue = button.dataset.paramValue
      if (paramName && paramValue) {
        this.el?.querySelectorAll("input").forEach((e: HTMLInputElement) => {
          if (e.dataset.paramName == paramName && e.value == paramValue) {
            e.checked = false;
            e.dispatchEvent(new Event('change'));
          }
        });
      }
    }

    private onWindowResized = (e:UIEvent):void => {
      if(this.vm) {
        window.setTimeout(eh.debounce(this.vm.windowResized, 100));
      }
    }

    private onWindowScroll = (e:UIEvent):void => {
      if(this.vm) {
        window.setTimeout(eh.debounce(this.vm?.recalculateFilterGroupWrapper, 10));
      }
    }

    private resetForm = (e: UIEvent):void => {
      this.vm?.reset();
      this.inputs?.forEach(x => x.checked=false);
      this.activeFiltersButtons?.forEach(x => {
        $(x).find("span.eh-icon").removeClass(EhFilterController.REMOVE_CLASSNAMES).addClass(EhFilterController.SPINNER_CLASSNAMES);
      })
      this.onChange(e,false);
    }

    private onChange = (e: UIEvent, addSpinner:boolean = true): void => {
      e.preventDefault();
      if (addSpinner) {
        $(e.currentTarget as HTMLInputElement).closest('.eh-accordion-ctrl--category-item').find("span.eh-icon").removeClass(EhFilterController.ARROW_UP_CLASSNAMES).addClass(EhFilterController.SPINNER_CLASSNAMES);
        $(e.currentTarget as HTMLInputElement).closest('ehel-filter--filters-selection-list').addClass("loading");
      }
      const list = this.el?.querySelectorAll(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`);
      if(list){
        list.forEach(x => { x.classList.add("loading")})
      }

      //e.stopPropagation();
      if (this.form && this.form?.dataset.snippetUrl) {
          const fd = new FormData(this.form);
          const data: any = {};
          fd.forEach((d, v) => {
              data[v] = d.toString()
          });
          let target = e.target as HTMLElement;
          let change = ($(target).is("input[type='checkbox']") && $(target).prop('checked')) ? 'filter_selected' : ($(target).selfOrClosest('.marker-filters-clear').length)?'filters_clear':'filter_reset';
          $(this.form).data('csTrackingAjaxChange', change);
          cs.Snippet.preAjax($(this.form.parentElement as HTMLElement), target);
          $(this.form).data('csTrackingAjaxActivator', undefined);
          $(this.form).data('csTrackingIsSnippetRequest', true);
          $.ajax(this.form.dataset.snippetUrl, {method: "POST", data: data}).done((d: string) => {
            const newDom = new DOMParser().parseFromString(d, "text/html");
            // replace:
            // result counts
            // filters
            const replaceMap: { [key: string]: HTMLElement } = {};
            const openFilters: string[] = []
            const openClones: string[] = []
            const openPills: string[] = []
            this.el?.querySelectorAll(`${AccordionCtrl.ACCORDION_CATEGORY_SELECTOR}`).forEach((e: HTMLElement) => {
              if (e.dataset.filterProperty && e.classList.contains("is-open")) {
                openFilters.push(e.dataset.filterProperty);
              }
              if (e.dataset.cloneName && e.classList.contains("is-open")) {
                openClones.push(e.dataset.cloneName);
              }
            });
            this.el?.querySelectorAll(`.ehel-filter--filter.is-active`).forEach((e: HTMLElement) => {
              if (e.dataset.filterProperty) {
                openPills.push(e.dataset.filterProperty);
              }
            });
            newDom.querySelectorAll(".ehel-product-finder--ajax-replace").forEach((e: HTMLElement) => {
              if (e.dataset.replaceId) {
                replaceMap[e.dataset.replaceId] = e;
                e.querySelectorAll(`${AccordionCtrl.ACCORDION_CATEGORY_SELECTOR}`).forEach((e1: HTMLElement) => {
                  if (e1.dataset.filterProperty) {
                    if (openFilters.find((v) => {
                      const rv = e1.dataset.filterProperty === v
                      return rv;
                    })) {
                      e1.classList.add("is-open");
                    }
                  }
                  if (e1.dataset.cloneName) {
                    if (openClones.find((v) => {
                      const rv = e1.dataset.cloneName === v
                      return rv;
                    })) {
                      e1.classList.add("is-open");
                    }
                  }
                });
                e.querySelectorAll(`.ehel-filter--filter`).forEach((e1: HTMLElement) => {
                  if (e1.dataset.filterProperty) {
                    if (openPills.find((v) => {
                      const rv = e1.dataset.filterProperty === v
                      return rv;
                    })) {
                      e1.classList.add("is-active");
                      e1.querySelector(`.${EhFilterController.ICON_CLASSNAME}`)?.classList.remove(EhFilterViewModel.ARROW_DOWN_CLASSNAME);
                      e1.querySelector(`.${EhFilterController.ICON_CLASSNAME}`)?.classList.add(EhFilterViewModel.ARROW_UP_CLASSNAME);
                      e1.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`)?.querySelector(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`)?.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
                    }
                  }
                })
              }
            });
            {
              const w: HTMLElement | null = newDom.querySelector(`.${EhFilterController.FILTER_WRAPPER}`);
              const newUrl = w?.dataset.url
              if (newUrl) {
                  history.pushState({}, "", newUrl);
              }
            }
            {
              const s: HTMLElement | null = newDom.querySelector(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`);
              const t: HTMLElement | null | undefined = this.el?.querySelector(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`);
              if (s && t) {
                  t.dataset.page = s.dataset.page
                  t.dataset.nextPage = s.dataset.nextPage
                  t.dataset.nextPageHistory = s.dataset.nextPageHistory
                  t.dataset.resultCount = s.dataset.resultCount
              }
            }

            this.el?.querySelectorAll(".ehel-product-finder--ajax-replace").forEach((e: HTMLElement) => {
              if (e.dataset.replaceId && replaceMap[e.dataset.replaceId]) {
                $(e).replaceWith(replaceMap[e.dataset.replaceId]);
                const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
                event.replacedTarget = $(replaceMap[e.dataset.replaceId]);
                event.removedTarget = $(e);
                $(':root').trigger(event);
              }
            });
            this.initDynamics();
            // reiinit acordion - NECESSARY?
            this.el?.querySelectorAll(`${eh.AccordionCtrl.ACCORDION_SELECTOR}`).forEach((e) => {
              // $(e).trigger(AccordionCtrl.RELOAD_EVENT)
              e.dispatchEvent(new Event(AccordionCtrl.RELOAD_EVENT));
            });

            // results - tiles
            this.el?.querySelectorAll(`.ehts-product-info-container .ehts-product-info`).forEach( (e:HTMLElement) => e.remove());
            const parentT = this.el?.querySelector(`.ehts-product-info-container`);

            if(parentT){
              newDom.querySelectorAll(`.ehts-product-info-container .ehts-product-info`).forEach((e: HTMLElement) => {
                parentT.appendChild(e);
                const ev = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
                ev.replacedTarget = $(e);
                $(':root').trigger(ev);
              });
            }
            
            this.el?.querySelectorAll(`.${EhLoadMoreController.LOAD_MORE_CLASSNAME}`).forEach((e) => {
              // $(e).trigger(AccordionCtrl.RELOAD_EVENT)
              e.dispatchEvent(new Event(EhLoadMoreController.RELOAD_EVENT));
            });
            // pager or refresh pager
            if (list) {
              list.forEach(x => {
                x.classList.add("loading")
              });
            }
          });
      }
    }

    private doFilterClick = (e: UIEvent): void => {
      let filter = e.currentTarget as HTMLElement;
      e.preventDefault();
      e.stopPropagation();
      this.vm?.showFilter(filter)
    }

    private doFilterLoadingClick = (e: UIEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      (e.currentTarget as HTMLElement).blur();
    }

    private onOpenMenuClick = (e: UIEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      (e.currentTarget as HTMLElement).blur();
      this.vm?.openMenu();
    }

    private onCloseMenuClick = (e: UIEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      (e.currentTarget as HTMLElement).blur();
      this.vm?.closeMenu();
    }

    private onScrollPageClicked = (e: MouseEvent | TouchEvent): void => {
      let el = e.target as HTMLElement;
      let x = $(el).closest(`.${EhFilterController.FILTER_CLASSNAME}`);
      if (x.length == 0) {
        this.vm?.clickRoot(el);
      }
    }

    private onMenuGroupScroll = (e: UIEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      if(this?.vm) {
        setTimeout(() => {
          if(this.vm) {
            eh.debounce(this.vm.onMenuGroupScroll, 40);
          }
        }, 0);
      }
    }

  }

  class EhFilterViewModel {

      static HIDDEN_CLASSNAME: string = 'ehtw-hidden';
      private static FLEX_WRAP_CLASSNAME: string = 'ehtw-flex-wrap';
      private static SHADOW_CLASSNAME: string = 'has-shadow';
      private static FILTER_MENU_OPENED_CLASSNAME: string = 'ehel-filter--filters-selection-opened';

      private static FILTER_SELECTED_CLASSNAME: string = 'ehel-product-finder--filters-selected';
      private static FILTER_SELECTED_WRAPPER_CLASSNAME: string = 'ehel-product-finder--filters-selected-wrapper';
      private static FILTER_SELECTED_ITEM_CLASSNAME: string = 'ehel-product-finder--filters-selected-item';
      private static FILTER_SELECTED_SHOW_MORE_CLASSNAME: string = 'ehel-product-finder--filters-selected-show-more';
      private static FILTER_SELECTED_SHOW_MORE_LABEL_CLASSNAME: string = 'eh-label';
      private static FILTER_SELECTED_CLEAR_BTN_CLASSNAME: string = 'ehel-product-finder--filters-selected-clear-btn';

      private static FILTER_MENU_HEADLINE_CLASSNAME: string = 'ehel-product-finder--filter-headline';
      private static FILTER_MENU_ACTIONS_CLASSNAME: string = 'ehel-product-finder--filters-actions';

      private static FILTER_MENU_FILTER_WRAPPER_CLASSNAME: string = 'ehel-product-finder--filter-wrapper';
      private static FILTER_MENU_BUTTON_LABEL_CLASSNAME: string = 'ehel-label';
  
      static ARROW_UP_CLASSNAME: string = 'icon-ehel-arrow-up';
      static ARROW_DOWN_CLASSNAME: string = 'icon-ehel-arrow-down';

      private static ACTIVE_CLASSNAME: string = 'is-active';
      private static OPEN_CLASSNAME: string = 'is-open';

      constructor(
        private productFinder: HTMLElement,
        private filters: NodeListOf<HTMLElement>,
        private menu: HTMLElement,
        private menuGroup: HTMLElement,
        private resultsMenu: HTMLElement
      ) { 
          setTimeout(() => {
              this.init();
              }, 20);
          
      }
      private init(): void {
        this.recalculateActiveFilters();
        this.productFinder?.querySelectorAll('.ehts-no-click-focus').forEach(elem => {
          elem.addEventListener('mousedown', this.noFocusOnClick);
        });  
      }

      private noFocusOnClick = (e: UIEvent): void => {
        e.preventDefault();
      }
  
      windowResized = (): void => {
        this.reset();
        this.recalculateActiveFilters();
        this.recalculateFilterGroupWrapper();
      }

      
      recalculateFilterGroupWrapper = (): void => { 
        const filters = this.resultsMenu?.querySelectorAll(`.${EhFilterViewModel.FILTER_MENU_FILTER_WRAPPER_CLASSNAME}`);
        const menuButton = this.resultsMenu?.querySelector(`.${EhFilterController.FILTER_FILTERS_OPEN_CLASSNAME}`);
        const menuButtonCount = menuButton?.querySelector(`.${EhFilterViewModel.FILTER_MENU_BUTTON_LABEL_CLASSNAME}`);
        const filtersWidth = this.resultsMenu.clientWidth - (menuButton?.clientWidth || 0) - 100;
  
        let sum = 0;
        //let count = 0;
        filters.forEach(filter => {
          filter.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
          sum += filter.clientWidth;
          if (sum >= filtersWidth) {
            filter.classList.add(EhFilterViewModel.HIDDEN_CLASSNAME);
            //count++;
          } else {
            filter.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
          }
        });
        // serverside ..
        //if (menuButtonCount && menuButtonCount.textContent) {
          //menuButtonCount.textContent = menuButtonCount.textContent.replace(/\(\d+\)/g, `(${count})`);
        //}
      }
  
      recalculateActiveFilters = (): void => {
        const filtersSelected = this.productFinder?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_CLASSNAME}`);
        const filtersSelectedWrapper = filtersSelected?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_WRAPPER_CLASSNAME}`);
        const activeFilters = filtersSelected?.querySelectorAll(`.${EhFilterViewModel.FILTER_SELECTED_ITEM_CLASSNAME}`);
        const showMore = filtersSelected?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_SHOW_MORE_CLASSNAME}`);
        const showMoreLabel = showMore?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_SHOW_MORE_LABEL_CLASSNAME}`);
        const clearAll = filtersSelected?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_CLEAR_BTN_CLASSNAME}`);
  
        this.recalculateFilterGroupWrapper();
        if (filtersSelected && activeFilters?.length) {
          filtersSelectedWrapper?.classList.remove(EhFilterViewModel.FLEX_WRAP_CLASSNAME);
          filtersSelectedWrapper?.removeEventListener('scroll', eh.debounce(this.onActiveFiltersScroll));
          filtersSelectedWrapper?.addEventListener('scroll', eh.debounce(this.onActiveFiltersScroll));
          const filtersSelectedWidth = filtersSelected.clientWidth - (clearAll?.clientWidth || 0) - 100;
          let sum = 0;
          let count = 0;

          activeFilters.forEach(activeFilter => {
            activeFilter.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
            sum += activeFilter.clientWidth;
            if (sum >= filtersSelectedWidth) {
              activeFilter.classList.add(EhFilterViewModel.HIDDEN_CLASSNAME);
              count++;
            } else {
              activeFilter.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
            }
          });

          if (count > 0) {
            showMore?.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
            showMore?.removeEventListener('click', this.doShowMoreFilters);
            showMore?.addEventListener('click', this.doShowMoreFilters);

            if (showMoreLabel) {
              showMoreLabel.textContent = `+${count}`;
            }
          } else {
            showMore?.classList.add(EhFilterViewModel.HIDDEN_CLASSNAME);
          }
        } 
      }

      private onActiveFiltersScroll = (): void => {
        const filtersSelected = this.productFinder?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_CLASSNAME}`);
        const filtersSelectedWrapper = filtersSelected?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_WRAPPER_CLASSNAME}`);

        const scrollLeft = filtersSelectedWrapper?.scrollLeft || 0;

        if (scrollLeft > 0) {
          this.resultsMenu.classList.add(EhFilterViewModel.SHADOW_CLASSNAME);
        } else {
          this.resultsMenu.classList.remove(EhFilterViewModel.SHADOW_CLASSNAME);
        }
      }

      private doShowMoreFilters = (e: UIEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        const filtersSelected = this.productFinder?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_CLASSNAME}`);
        const activeFilters = filtersSelected?.querySelectorAll(`.${EhFilterViewModel.FILTER_SELECTED_ITEM_CLASSNAME}`);
        const filtersSelectedWrapper = filtersSelected?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_WRAPPER_CLASSNAME}`);
        const showMore = filtersSelected?.querySelector(`.${EhFilterViewModel.FILTER_SELECTED_SHOW_MORE_CLASSNAME}`);

        //console.log(showMore, filtersSelectedWrapper);

        showMore?.classList.add(EhFilterViewModel.HIDDEN_CLASSNAME);
        filtersSelectedWrapper?.classList.add(EhFilterViewModel.FLEX_WRAP_CLASSNAME);

        activeFilters?.forEach(activeFilter => activeFilter.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME));
      }

      public openMenu(): boolean {
        this.reset();
        this.productFinder.classList.add(EhFilterViewModel.FILTER_MENU_OPENED_CLASSNAME);
        eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll');
        this.onMenuGroupScroll();
        return true;
      }

      public closeMenu(): boolean {
        this.productFinder.classList.remove(EhFilterViewModel.FILTER_MENU_OPENED_CLASSNAME);
        eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll');
        return true;
      }

      public showFilter(filter: HTMLElement | null): boolean {
        if (filter) {
          if (filter.classList.contains(EhFilterViewModel.ACTIVE_CLASSNAME)) {
            this.reset();
          } else {
            this.reset();
          
            filter.classList.add(EhFilterViewModel.ACTIVE_CLASSNAME);
    
            const icon = filter.querySelector(`.${EhFilterController.ICON_CLASSNAME}`);
            if (icon) {
              icon.classList.remove(EhFilterViewModel.ARROW_DOWN_CLASSNAME);
              icon.classList.add(EhFilterViewModel.ARROW_UP_CLASSNAME);
            }
            const wrapper = filter.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`);
            if (wrapper) {
              const list = wrapper.querySelector(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`);
              if (list) {
                list.classList.remove(EhFilterViewModel.HIDDEN_CLASSNAME);
              }
            }
          }
          
        }
        return true;
      }

      public reset() {
        const filtersWrapper = this.menuGroup?.querySelectorAll(`.${EhFilterViewModel.FILTER_MENU_FILTER_WRAPPER_CLASSNAME}`);
        this.filters.forEach(filter => {
          filter.classList.remove(EhFilterViewModel.ACTIVE_CLASSNAME);
          const icon = filter.querySelector(`.${EhFilterController.ICON_CLASSNAME}`);
          if (icon) {
            icon.classList.remove(EhFilterViewModel.ARROW_UP_CLASSNAME);
            icon.classList.add(EhFilterViewModel.ARROW_DOWN_CLASSNAME);
          }
          const wrapper = filter.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`);
          if (wrapper) {
            const list = wrapper.querySelector(`.${EhFilterController.FILTER_SELECTION_LIST_CLASSNAME}`);
            if (list) {
              list.classList.add(EhFilterViewModel.HIDDEN_CLASSNAME);
            }
          }
        });
        filtersWrapper.forEach(filter => {
          filter.classList.remove(EhFilterViewModel.OPEN_CLASSNAME);
        });
  
      }

      public clickRoot(elem: HTMLElement | null) {
        const filterActive = elem?.closest(`.${EhFilterController.FILTER_WRAPPER_CLASSNAME}`);
        if (!filterActive) {
          this.reset();
        }
      }

      public onMenuGroupScroll = (): void => {
        let childHeight = 0;
        this.menuGroup.children
        for (let i=0; i < this.menuGroup.children.length; i++) {
          childHeight += this.menuGroup.children[i].clientHeight;
        }
        if (childHeight > this.menuGroup.clientHeight) {
          if (this.menuGroup.scrollTop + this.menuGroup.clientHeight >= this.menuGroup.scrollHeight) {
            this.menu.querySelector(`.${EhFilterViewModel.FILTER_MENU_ACTIONS_CLASSNAME}`)?.classList.remove(EhFilterViewModel.SHADOW_CLASSNAME);
          } else {
            this.menu.querySelector(`.${EhFilterViewModel.FILTER_MENU_ACTIONS_CLASSNAME}`)?.classList.add(EhFilterViewModel.SHADOW_CLASSNAME);
          }
  
          if (this.menuGroup.scrollTop > 0) {
            this.menu.querySelector(`.${EhFilterViewModel.FILTER_MENU_HEADLINE_CLASSNAME}`)?.classList.add(EhFilterViewModel.SHADOW_CLASSNAME);
          } else {
            this.menu.querySelector(`.${EhFilterViewModel.FILTER_MENU_HEADLINE_CLASSNAME}`)?.classList.remove(EhFilterViewModel.SHADOW_CLASSNAME);
          }
        } else {
          this.menu.querySelector(`.${EhFilterViewModel.FILTER_MENU_ACTIONS_CLASSNAME}`)?.classList.remove(EhFilterViewModel.SHADOW_CLASSNAME);
          this.menu.querySelector(`.${EhFilterViewModel.FILTER_MENU_HEADLINE_CLASSNAME}`)?.classList.remove(EhFilterViewModel.SHADOW_CLASSNAME);
        }
      }
  }
}
