namespace eh {
/**
 * Controller to manage anchor-navigation behaviour. 
 */
export class EhGalleryController {

    public static GALLERY_CLASSNAME: string = 'ehel-gallery';
    public static GALLERY_NAVIGATION_CLASSNAME: string = 'ehel-gallery--navigation';
    public static GALLERY_NAVIGATION_ITEM_CLASSNAME: string = 'ehel-button';

    public static GALLERY_PREVIOUS_BTN_CLASSNAME: string = 'ehel-gallery--prev-btn';
    public static GALLERY_NEXT_BTN_CLASSNAME: string = 'ehel-gallery--next-btn';

    public static GALLERY_PCITURE_ITEM_CLASSNAME: string = 'ehel-gallery--picture';
    public static GALLERY_PCITURE_TEXT_ITEM_CLASSNAME: string = 'ehel-gallery--picture-text';

    static ehInit($base: JQuery<HTMLElement>): void {
        $(`.${EhGalleryController.GALLERY_CLASSNAME}`, $base).each((index: number, element: HTMLElement) => {
            new EhGalleryController(element);
        });
    }

    private el: HTMLElement | null | undefined;
    private navigation: HTMLElement | null | undefined;
    private navigationItems: NodeListOf<HTMLElement> | undefined;

    private previousBtn: HTMLElement | null | undefined;
    private nextBtn: HTMLElement | null | undefined;
    private vm: EhGalleryViewModel;

    constructor(
        private readonly base: HTMLElement | null,
    ) {

        this.el = this.base;//?.querySelector(`.${EhGalleryController.GALLERY_CLASSNAME}`);
        this.navigation = this.el?.querySelector(`.${EhGalleryController.GALLERY_NAVIGATION_CLASSNAME}`);
        this.navigationItems = this.navigation?.querySelectorAll(`.${EhGalleryController.GALLERY_NAVIGATION_ITEM_CLASSNAME}`);
        this.previousBtn = this.el?.querySelector(`.${EhGalleryController.GALLERY_PREVIOUS_BTN_CLASSNAME}`);
        this.nextBtn = this.el?.querySelector(`.${EhGalleryController.GALLERY_NEXT_BTN_CLASSNAME}`);
      
        if (!this.navigation || !this.el) {
            //throw new Error(`Missing required element`);
            return;
        }

        this.vm = new EhGalleryViewModel(
            this.el,
            this.navigation, 
            this.navigationItems,
            this.previousBtn,
            this.nextBtn,
        );
        
        if (!this.vm.isDisabled) {
            this.init();
        }
    }

    private init(): void {
        this.registerControls();
    }

    private registerControls(): void {
        this.previousBtn?.addEventListener('click', this.onPreviousClicked);
        this.nextBtn?.addEventListener('click', this.onNextClicked);
        this.navigationItems?.forEach((item: HTMLElement): void => {
            item.addEventListener('click', this.onItemClicked);
        });
    }

    private onItemClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        this.vm.setCurrentItem(e.currentTarget as HTMLElement);
    }

    private onPreviousClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        (document?.activeElement as HTMLElement).blur();
        this.vm.previous();
    }

    private onNextClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        (document?.activeElement as HTMLElement).blur();
        this.vm.next();
    }

}

interface IScrollerItem {
    idx: number;
    link: HTMLElement;
    target: HTMLElement;
    targetText: HTMLElement;
    // thresholdBegin: number;
    // thresholdEnd: number;
}

class EhGalleryViewModel {

    public isDisabled: boolean = false;

    private static ACTIVE_CLASSNAME: string = 'is-active';
    private static DISABLED_CLASSNAME: string = 'is-disabled';
    private static HIDE_CLASSNAME: string = 'ehtw-hidden';
    private static TRANSITION_EASING_LINEAR: string = 'linear';
    private static TRANSITION_DURATION: number = 400;
    private static MIN_AMOUNT_SCROLL_ITEMS: number = 1;
    private _currentItem: IScrollerItem | undefined;
    private _scrollerItems: IScrollerItem[] = [];
    private targetScrollPosTop: number = 0;
    private targetScrollPosLeft: number = 0;
    
    private isVertical = true;

    constructor(
        private gallery: HTMLElement,
        private scroller: HTMLElement,
        private items: NodeListOf<HTMLElement> | undefined,
        private previousButton: HTMLElement | null | undefined,
        private nextButton: HTMLElement | null | undefined,
    ) {
        this.init();
    }

    public previous(): void {
        if (!this._currentItem) {
            return this.setCurrentItem(this.getItemByIdx(0).link);
        }
        const targetIndex: number = Math.max(0, this._currentItem.idx - 1)
        const previousItem: IScrollerItem = this.getItemByIdx(targetIndex);
        this.setCurrentItem(previousItem.link);
    }
    
    public next(): void {
        if (!this._currentItem) {
            return this.setCurrentItem(this.getItemByIdx(0).link);
        }
        const targetIndex: number = Math.min(this._scrollerItems.length - 1, this._currentItem.idx + 1);
        const nextItem: IScrollerItem = this.getItemByIdx(targetIndex);
        this.setCurrentItem(nextItem.link);
    }

    public setCurrentItem(item: HTMLElement | undefined, triggerJumpToAnchor: boolean = true): void {
        if (this._currentItem) {
            this._currentItem.target.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
            this._currentItem.targetText.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
            this._currentItem.link.classList.remove(EhGalleryViewModel.ACTIVE_CLASSNAME);
            this._currentItem.link.blur();
        }
        this._currentItem = this.getItemByEl(item);
        this._currentItem?.link.classList.add(EhGalleryViewModel.ACTIVE_CLASSNAME);
        this._currentItem?.target.classList.remove(EhGalleryViewModel.HIDE_CLASSNAME);
        this._currentItem?.targetText.classList.remove(EhGalleryViewModel.HIDE_CLASSNAME);

        let index = this._scrollerItems.findIndex(si => si.link === item);
        if (index === 0) {
            this.previousButton?.classList.add(EhGalleryViewModel.DISABLED_CLASSNAME);
        } else {
            this.previousButton?.classList.remove(EhGalleryViewModel.DISABLED_CLASSNAME);
        }
        if (index === this._scrollerItems.length - 1) {
            this.nextButton?.classList.add(EhGalleryViewModel.DISABLED_CLASSNAME);
        } else {
            this.nextButton?.classList.remove(EhGalleryViewModel.DISABLED_CLASSNAME);
        }

        //this.invalidateControls();
        this.alignItemToViewport(this._currentItem);
    }

    private init(): void {
        this.invalidateItems();
        if (this._scrollerItems.length < EhGalleryViewModel.MIN_AMOUNT_SCROLL_ITEMS) {
            this.isDisabled = true;
            this.scroller.classList.add(EhGalleryViewModel.HIDE_CLASSNAME);
            this._scrollerItems = [];
            return;
        }
        setTimeout(() => {
            this.invalidateControls();
        }, 0);

        this.setCurrentItem(this.getItemByIdx(0).link);
        eh.Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
    }

    private onBreakpointChange: (old: eh.Breakpoint | null, current: eh.Breakpoint) => void = (_old: eh.Breakpoint | null, _current: eh.Breakpoint): void => {
        this.isVertical = !_current.isMobile;
    };

   private alignItemToViewport(item: IScrollerItem | undefined): void {
        if (!item) {
            return;
        }
        if (this.isVertical) {
          const scrollerPos: number = this.scroller.scrollTop;
          let clipping: number = item.link.offsetTop - scrollerPos;

          this.targetScrollPosTop = scrollerPos + clipping;
            
          if (this.scroller.scrollTop !== this.targetScrollPosTop) {
            $(this.scroller).animate({
                scrollTop: this.targetScrollPosTop
            },
            EhGalleryViewModel.TRANSITION_DURATION,
            EhGalleryViewModel.TRANSITION_EASING_LINEAR);
          }
        } else {
          const scrollerPos: number = this.scroller.scrollLeft;
          let clipping: number = item.link.offsetLeft -  scrollerPos;

        
          this.targetScrollPosLeft = scrollerPos + clipping;
              

          if (this.scroller.scrollLeft !== this.targetScrollPosLeft) {
            $(this.scroller).animate({
                scrollLeft: this.targetScrollPosLeft
            },
            EhGalleryViewModel.TRANSITION_DURATION,
            EhGalleryViewModel.TRANSITION_EASING_LINEAR);
          }
        }
    }

    private getItemByEl(el: HTMLElement | undefined): IScrollerItem | undefined {
      return this._scrollerItems.find(si => si.link === el);
    }

    private getItemByIdx(idx: number): IScrollerItem {
      if (idx < 0 || idx > this._scrollerItems.length -1) {
          throw new Error(`Out of bounds. Failed to invalidate controls`);
      }
      return this._scrollerItems[idx];
    }

    private invalidateItems = (): void => {
      this._scrollerItems = [];
      let idx: number = 0;
      this.items?.forEach((value: HTMLElement): void => {
          const targetKey: string | null = value.getAttribute('data-idx') || '';
          const target: HTMLElement | null = this.gallery.querySelector(`.${EhGalleryController.GALLERY_PCITURE_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          const targetText: HTMLElement | null = this.gallery.querySelector(`.${EhGalleryController.GALLERY_PCITURE_TEXT_ITEM_CLASSNAME}[data-idx='${targetKey}']`);
          const isDisabled: boolean = value.classList.contains(EhGalleryViewModel.DISABLED_CLASSNAME);
          if (!target || !targetText || isDisabled) {
              return;
          }

          this._scrollerItems.push({
              idx: idx++,
              link: value,
              target: target,
              targetText: targetText
              
          });
      });
    }

    public invalidateControls = (): void => {
      const hideClass: string = EhGalleryViewModel.HIDE_CLASSNAME;

      this.previousButton?.classList.add(hideClass);
      this.nextButton?.classList.add(hideClass);

      if (this.isVertical) {
        if (this.scroller.scrollTop > 0 || this.scroller.clientHeight + this.scroller.scrollTop < this.scroller.scrollHeight) {
            this.previousButton?.classList.remove(hideClass);
            this.nextButton?.classList.remove(hideClass);
        }
      } else {
          if (this.scroller.scrollLeft > 0 || this.scroller.clientWidth + this.scroller.scrollLeft < this.scroller.scrollWidth) {
              this.previousButton?.classList.remove(hideClass);
              this.nextButton?.classList.remove(hideClass);
          }
      }
    }
  }
}
