namespace eh {

  export class BreadcrumbController {
    public static BREADCRUMB_WRAPPER: string = 'ehel-breadcrumb';
    public static BREADCRUMB_SUBLIST_OPEN_WRAPPER: string = 'ehel-breadcrumb--sublist-open';
    public static BREADCRUMB_SUBLIST_WRAPPER: string = 'ehel-breadcrumb--sublist';
    public static BREADCRUMB_LAST_ITEM: string = 'ehel-breadcrumb--segment-last';

    static init($base: JQuery<HTMLElement>): void {
      $(`.${BreadcrumbController.BREADCRUMB_WRAPPER}`, $base).each((index, element) => {
        new BreadcrumbController(element);
      });
    }

    private sublist: HTMLElement | null | undefined;
    private sublistOpen: HTMLElement | null | undefined;
    private breadcrumbLast: HTMLElement | null | undefined;

    private vm: BreadcrumbViewModel | null | undefined = null;
    private root: HTMLElement = document.scrollingElement as HTMLElement;

    constructor(private readonly el: HTMLElement) {
      this.sublist = this.el?.querySelector(`.${BreadcrumbController.BREADCRUMB_SUBLIST_WRAPPER}`) as HTMLElement;
      this.sublistOpen = this.el?.querySelector(`.${BreadcrumbController.BREADCRUMB_SUBLIST_OPEN_WRAPPER}`) as HTMLElement;
      this.breadcrumbLast = this.el?.querySelector(`.${BreadcrumbController.BREADCRUMB_LAST_ITEM}`) as HTMLElement;

      if (!this.sublist && !this.breadcrumbLast) {
        return;
      }
      this.vm = new BreadcrumbViewModel(
        this.el,
        this.sublist,
        this.breadcrumbLast
      );
      this.init();
    }

    private init(): void {
      this.registerControls();
    }

    private registerControls(): void {
      this.root.addEventListener('click', this.onClickRoot);
      this.sublistOpen?.addEventListener('click', this.onClickOpenSublist);
    }

    private onClickOpenSublist = (e: UIEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      this.vm?.toggleSublist();
    }

    private onClickRoot = (e: UIEvent): void => {
      if(this.vm?.isOpen()) {
        this.vm?.closeSublist();
      }
    }
  }

  class BreadcrumbViewModel {
    private static HIDDEN_CLASSNAME: string = '!ehtw-hidden';
    private static TOOLTIP_CLASSNAME: string = 'ehel-tooltip';

    constructor(
      private wrapper: HTMLElement,
      private sublist: HTMLElement | null,
      private breadcrumbLast: HTMLElement | null = null
    ) {
      this.init();
    }

    private init(): void {
      eh.Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange); 
    }
  
    public isOpen():boolean {
      return !this.sublist?.classList.contains(BreadcrumbViewModel.HIDDEN_CLASSNAME);
    }

    public toggleSublist() {
      if (this.sublist) {
        if (this.sublist.classList.contains(BreadcrumbViewModel.HIDDEN_CLASSNAME)) {
          this.sublist.classList.remove(BreadcrumbViewModel.HIDDEN_CLASSNAME);
        } else {
          this.sublist.classList.add(BreadcrumbViewModel.HIDDEN_CLASSNAME);
        }
      }
    }

    public closeSublist() {
      this.sublist?.classList.add(BreadcrumbViewModel.HIDDEN_CLASSNAME);
    }

    private onBreakpointChange: (old: Breakpoint | null, current: Breakpoint) => void = (_old: Breakpoint | null, _current: Breakpoint): void => {
      if (this.breadcrumbLast) {
        const firstChild = this.breadcrumbLast.firstElementChild as HTMLElement;
        if (firstChild) {
          if (firstChild.offsetWidth < firstChild.scrollWidth) {
            if (firstChild && !firstChild.classList.contains(BreadcrumbViewModel.TOOLTIP_CLASSNAME)) {
              firstChild.classList.add(BreadcrumbViewModel.TOOLTIP_CLASSNAME);
            }
          } else {
            if (firstChild && firstChild.classList.contains(BreadcrumbViewModel.TOOLTIP_CLASSNAME)) {
              firstChild.classList.remove(BreadcrumbViewModel.TOOLTIP_CLASSNAME);
            }
          }
        }
      }
    };
  
  }
}