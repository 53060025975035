namespace eh {
    
  /**
   * Controller to manage anchor-navigation behaviour (part of pc_modules). 
  */
  export class EhAnchorNavigationController {
       
  static init($base: JQuery<HTMLElement>): void {
      $(`.${ EhAnchorNavigationController.ANCHOR_NAV_CLASSNAME }`, $base).each((index, element) => {
        new EhAnchorNavigationController(element);
      });    
  }

  // check #anchor target is available or remove
  static filterAnchorItemsExist(navigation: HTMLElement | null | undefined):NodeListOf<HTMLElement> | undefined {
    let navItems = navigation?.querySelectorAll(`.${EhAnchorNavigationController.ANCHOR_NAV_NAVIGATION_ITEM_CLASSNAME}`);
    if(navItems) {
      navItems.forEach( ni => {
        let hf = $(ni).attr('href');
        if(hf && hf.startsWith('#')) {
          if($(hf).length == 0) {
            $(ni).remove();
          }
        }
      });
      return navigation?.querySelectorAll(`.${EhAnchorNavigationController.ANCHOR_NAV_NAVIGATION_ITEM_CLASSNAME}`);
    }
    return undefined;
  }
  public static ANCHOR_NAV_CLASSNAME: string = 'ehel-anchor-navigation';
  public static ANCHOR_NAV_NAVIGATION_CLASSNAME: string = 'ehel-anchor-navigation--navigation';
  public static ANCHOR_NAV_NAVIGATION_ITEM_CLASSNAME: string = 'ehel-button';

  public static ANCHOR_NAV_PREVIOUS_BTN_CLASSNAME: string = 'ehel-anchor-navigation--prev-btn';
  public static ANCHOR_NAV_NEXT_BTN_CLASSNAME: string = 'ehel-anchor-navigation--next-btn';

  public static TABLE_OF_CONTENT_CLASSNAME: string = 'ehel-table-of-content';
  public static ANCHOR_NAV_ACTIVE_LABEL_CLASSNAME: string = 'ehel-anchor-navigation--active-label';

    private el: HTMLElement | null | undefined;
    private navigation: HTMLElement | null | undefined;
    private navigationItems: NodeListOf<HTMLElement> | undefined;

    private tableOfContent: HTMLElement | null | undefined;
    private tableOfContentElements: HTMLElement | null | undefined;
    private anchorActiveLabel: HTMLElement | null | undefined;

    private previousBtn: HTMLElement | null | undefined;
    private nextBtn: HTMLElement | null | undefined;
    private vm: EhAnchorNavigationViewModel;

    private root: HTMLElement = document.scrollingElement as HTMLElement;

    constructor(
      private readonly base: HTMLElement | null,
    ) {
      this.el = this.base;//?.querySelector(`.${EhAnchorNavigationController.ANCHOR_NAV_CLASSNAME}`);
      this.navigation = this.el?.querySelector(`.${EhAnchorNavigationController.ANCHOR_NAV_NAVIGATION_CLASSNAME}`);
      this.navigationItems = EhAnchorNavigationController.filterAnchorItemsExist(this.navigation);
      this.previousBtn = this.el?.querySelector(`.${EhAnchorNavigationController.ANCHOR_NAV_PREVIOUS_BTN_CLASSNAME}`);
      this.nextBtn = this.el?.querySelector(`.${EhAnchorNavigationController.ANCHOR_NAV_NEXT_BTN_CLASSNAME}`);
      
      this.tableOfContent = this.el?.querySelector(`.${EhAnchorNavigationController.TABLE_OF_CONTENT_CLASSNAME}`);
      this.tableOfContentElements = this.tableOfContent?.querySelector(`.${EhAnchorNavigationController.ANCHOR_NAV_NAVIGATION_CLASSNAME}`);
      this.anchorActiveLabel = this.el?.querySelector(`.${EhAnchorNavigationController.ANCHOR_NAV_ACTIVE_LABEL_CLASSNAME}`);

      if (!this.navigation) {
        //throw new Error(`Missing required element`);
        return;
      }

      this.vm = new EhAnchorNavigationViewModel(
        this.navigation, 
        this.navigationItems,
        this.previousBtn,
        this.nextBtn,
        this.tableOfContent,
        this.tableOfContentElements,
        this.anchorActiveLabel
      );
      
      if (!this.vm.isDisabled) {
        this.init();
      } else {
        // ugly workaround to dispose sticky header behaviour. 
        // better would be to attach displacing elements only on demand via event.
        // the feature is allready in use by product-tabs with the classname `eh-tabs--products-sticky`.
        this.el?.classList.add('!ehtw-hidden');
        this.el?.classList.remove('eh-tabs--products-sticky');
        eh.Header.init($(document.body));
      }
    }

    private init(): void {
      this.registerControls();
      NavigationController.uiStateSupport.registerUIStateListener(this.onMainNavChanged);
    }

    private registerControls(): void {
      this.previousBtn?.addEventListener('click', this.onPreviousClicked);
      this.nextBtn?.addEventListener('click', this.onNextClicked);
      this.navigationItems?.forEach((item: HTMLElement): void => {
          item.addEventListener('click', this.onItemClicked);
      });
      this.tableOfContent?.addEventListener('click', this.onTableOfContentClicked);
      this.root.addEventListener('click', this.onClickRoot);
    }

    private onItemClicked = (e: MouseEvent | TouchEvent): void => {
      e.preventDefault();
      this.vm.setCurrentItem(e.currentTarget as HTMLElement);
    }

    private onPreviousClicked = (e: MouseEvent | TouchEvent): void => {
      e.preventDefault();
      this.vm.previous();
    }

    private onNextClicked = (e: MouseEvent | TouchEvent): void => {
        e.preventDefault();
        this.vm.next();
    }

    private onTableOfContentClicked = (e: MouseEvent | TouchEvent): void => {
      e.preventDefault();
      this.vm.tableOfContentClicked();
    }

    private onClickRoot = (e: MouseEvent | TouchEvent): void => {
      if(e.target instanceof HTMLElement) {
        if($(e.target as HTMLElement).selfOrClosest(`.${EhAnchorNavigationController.ANCHOR_NAV_CLASSNAME}`).length == 0) {
          this.vm?.closeTableOfContent();
        }
      }
    }

    private onMainNavChanged: (last: UIStateChanged, current: UIStateChanged) => void = (last: UIStateChanged, current: UIStateChanged): void => {
      if(eh.Breakpoints.getInstance().isMobile) {
        return;
      }
      // console.log('search rec:', current.state);
      if(current.state == UIStateValue.OPENED) {
        document.body.classList.add('eh-header-main-nav-opened');// disable anchor-nav
      } 
      if(current.state == UIStateValue.CLOSED) {
        document.body.classList.remove('eh-header-main-nav-opened');// disable anchor-nav
      } 
    }
  }

  interface IScrollerItem {
    idx: number;
    link: HTMLElement;
    target: HTMLElement;
    thresholdBegin: number;
    thresholdEnd: number;
  }

  class EhAnchorNavigationViewModel {
    public isDisabled: boolean = false;

    private static OPENED_CLASSNAME: string = 'is-opened';
    private static ACTIVE_CLASSNAME: string = 'is-active';
    private static DISABLED_CLASSNAME: string = 'is-disabled';
    private static HIDE_CLASSNAME: string = 'ehtw-hidden';
    private static TRANSITION_EASING_LINEAR: string = 'linear';
    private static TRANSITION_EASING_SWING: string = 'swing';
    private static TRANSITION_DURATION: number = 400;
    private static MIN_AMOUNT_SCROLL_ITEMS: number = 2;
    private _currentItem: IScrollerItem | undefined;
    private _scrollerItems: IScrollerItem[] = [];
    private targetScrollPosLeft: number = 0;
    private navigationHeight: number = 0;

    constructor(
        private scroller: HTMLElement,
        private items: NodeListOf<HTMLElement> | undefined,
        private previousButton: HTMLElement | null | undefined,
        private nextButton: HTMLElement | null | undefined,
        private tableOfContent: HTMLElement | null | undefined,
        private tableOfContentElements: HTMLElement | null | undefined,
        private anchorActiveLabel: HTMLElement | null | undefined,
    ) {
        this.init();
    }

    public previous(): void {
        if (!this._currentItem) {
            return this.setCurrentItem(this.getItemByIdx(0).link);
        }
        const targetIndex: number = Math.max(0, this._currentItem.idx - 1)
        const previousItem: IScrollerItem = this.getItemByIdx(targetIndex);
        this.setCurrentItem(previousItem.link);
    }
    
    public next(): void {
        if (!this._currentItem) {
            return this.setCurrentItem(this.getItemByIdx(0).link);
        }
        const targetIndex: number = Math.min(this._scrollerItems.length - 1, this._currentItem.idx + 1);
        const nextItem: IScrollerItem = this.getItemByIdx(targetIndex);
        this.setCurrentItem(nextItem.link);
    }

    public setCurrentItem(item: HTMLElement | undefined, triggerJumpToAnchor: boolean = true): void {
        if (this._currentItem) {
            this._currentItem.link.classList.remove(EhAnchorNavigationViewModel.ACTIVE_CLASSNAME);
            this._currentItem.link.blur();
        }
        this._currentItem = this.getItemByEl(item);
        this._currentItem?.link.classList.add(EhAnchorNavigationViewModel.ACTIVE_CLASSNAME);
        if (this.anchorActiveLabel) {
          this.anchorActiveLabel.innerText = this._currentItem?.link.innerText || this.anchorActiveLabel.dataset['label'] || '';
        }
          this.updateLocation();
        this.alignItemToViewport(this._currentItem);
        if (triggerJumpToAnchor) {
            this.animateToAnchor(this._currentItem);
        }
        this.invalidateControls();
        if (!this._currentItem && item) {
            this.onPageScrollChange();
        }
    }

    private updateLocation() {
        if(this._currentItem) {
            const targetKey = this._currentItem.link.getAttribute('href') || '';
            history.replaceState(history.state, '', window.location.pathname + window.location.search + targetKey);
        }
    }

    private init(): void {
        this.navigationHeight = this.scroller.clientHeight;
        this.invalidateItems();
        
        if (this._scrollerItems.length < EhAnchorNavigationViewModel.MIN_AMOUNT_SCROLL_ITEMS) {
            this.isDisabled = true;
            this.scroller.classList.add(EhAnchorNavigationViewModel.HIDE_CLASSNAME);
            this._scrollerItems = [];
            return;
        }

        eh.Breakpoints.getInstance().registerChangeListener(this.onBreakpointChange);
        eh.ScrollPage.registerChangeListener(eh.debounce(this.onPageScrollChange, 40));
        window.addEventListener('resize', eh.debounce(this.invalidate, 40));
        const initialSelection: HTMLElement | null = this.scroller?.querySelector(`.${EhAnchorNavigationViewModel.ACTIVE_CLASSNAME}`) as HTMLElement;
        if (initialSelection) {
            this.setCurrentItem(initialSelection);
        }
        this.invalidateControls();
        this.scroller.addEventListener('scroll', eh.debounce(this.onNavigationScrollChange, 40), {passive: true});

        // fixes invalide thresholdBegin values by resizings after images loaded; eg pc_gallery
        Promise.all(
            Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { 
                img.onload = img.onerror = resolve; 
            }))).then(() => {
                this.invalidateItems();
            }
        );
    }

    private onNavigationScrollChange = (): void => {
        this.targetScrollPosLeft = this.scroller.scrollLeft;
        this.invalidateControls();
    };

    private onPageScrollChange = (): void => {
        const scrollPos: number = eh.ScrollPage.getScrollPosition();
        const scrollerItem: IScrollerItem | undefined = this.getItemByOffset(scrollPos);
        this.setCurrentItem(scrollerItem?.link, false);
    };

    private onBreakpointChange: (old: Breakpoint | null, current: Breakpoint) => void = (_old: Breakpoint | null, _current: Breakpoint): void => {
        this.onPageScrollChange();
    };

    private alignItemToViewport(item: IScrollerItem | undefined): void {
        if (!item) {
            return;
        }
        const leftOffset: number = item.idx > 0 ? this.navigationHeight : 0;
        const rightOffset: number = item.idx < this._scrollerItems.length -1 ? this.navigationHeight : 0;
        
        const scrollerPos: number = this.scroller.scrollLeft;
        let clippingLeft: number = item.link.offsetLeft - leftOffset - scrollerPos;
        let clippingRight: number = item.link.offsetLeft + item.link.clientWidth - this.scroller.clientWidth + rightOffset - scrollerPos;

        if (clippingLeft < 0) {
            this.targetScrollPosLeft = scrollerPos + Math.min(0, clippingLeft);
        }
        if (clippingRight > 0) {
            this.targetScrollPosLeft = scrollerPos + Math.max(0, clippingRight);
        }

        if (this.scroller.scrollLeft !== this.targetScrollPosLeft) {
            $(this.scroller).animate({
                scrollLeft: this.targetScrollPosLeft
            },
            EhAnchorNavigationViewModel.TRANSITION_DURATION,
            EhAnchorNavigationViewModel.TRANSITION_EASING_LINEAR);
        }

    }

    private animateToAnchor(item: IScrollerItem | undefined): void {
        const el: Element | null = document.scrollingElement;
        if (!el ) {
            return;
        }
        $(el).animate({
            scrollTop: item?.thresholdBegin
        },
        EhAnchorNavigationViewModel.TRANSITION_DURATION,
        EhAnchorNavigationViewModel.TRANSITION_EASING_SWING);
    }

    private getItemByEl(el: HTMLElement | undefined): IScrollerItem | undefined {
        return this._scrollerItems.find(si => si.link === el);
    }

    private getItemByIdx(idx: number): IScrollerItem {
        if (idx < 0 || idx > this._scrollerItems.length -1) {
            throw new Error(`Out of bounds. Failed to invalidate controls`);
        }
        return this._scrollerItems[idx];
    }

    private getItemByOffset(offset: number): IScrollerItem | undefined {
        return this._scrollerItems.find(i => {
            return i.thresholdBegin <= offset 
                && i.thresholdEnd > offset;
        });
    }

    private invalidate = (): void => {
        this.invalidateControls();
        this.invalidateItems();
    }

    private invalidateItems = (): void => {
        this._scrollerItems = [];
        let idx: number = 0;
        this.items?.forEach((value: HTMLElement): void => {
            const targetKey: string | null = value.getAttribute('href') || '';
            const target: HTMLElement | null = document.getElementById(targetKey.replace('#', ''));
            const isDisabled: boolean = value.classList.contains(EhAnchorNavigationViewModel.DISABLED_CLASSNAME);
            if (!target || isDisabled) {
                return;
            }
            const topPos: number = eh.offsetTopToBody(target) - this.navigationHeight;
            this._scrollerItems.push({
                idx: idx++,
                link: value,
                target: target,
                thresholdBegin: topPos,
                thresholdEnd: topPos + target.getBoundingClientRect().height,
            });
        });
    }

    private invalidateControls = (): void => {
        const hideClass: string = EhAnchorNavigationViewModel.HIDE_CLASSNAME;

        // no active overflow
        if (this.scroller.scrollWidth === this.scroller.clientWidth) {
            this.previousButton?.classList.add(hideClass);
            this.nextButton?.classList.add(hideClass);
            return;
        }
        
        // handle previous button ctrl
        if (this.targetScrollPosLeft > 0) {
            this.previousButton?.classList.remove(hideClass);
        } else {
            this.previousButton?.classList.add(hideClass);
        }
        
        // handle next button ctrl
        const scrollerDelta: number = this.scroller.scrollWidth - this.scroller.clientWidth - 1;
        if (this.targetScrollPosLeft < scrollerDelta) {
            this.nextButton?.classList.remove(hideClass);
        } else {
            this.nextButton?.classList.add(hideClass);
        }

    }

    public tableOfContentClicked(): void {
      if (this.tableOfContent && eh.Breakpoints.getInstance().isMobile) {
        if (this.tableOfContent.classList.contains(EhAnchorNavigationViewModel.OPENED_CLASSNAME)) {
            this.tableOfContent.classList.remove(EhAnchorNavigationViewModel.OPENED_CLASSNAME);
            this.tableOfContentElements?.classList.add(EhAnchorNavigationViewModel.HIDE_CLASSNAME);
            
        } else {
            this.tableOfContent.classList.add(EhAnchorNavigationViewModel.OPENED_CLASSNAME);
            this.tableOfContentElements?.classList.remove(EhAnchorNavigationViewModel.HIDE_CLASSNAME);

        }
      }
    }

    public closeTableOfContent() { 
      if (this.tableOfContent) {
        this.tableOfContent.classList.remove(EhAnchorNavigationViewModel.OPENED_CLASSNAME);
        this.tableOfContentElements?.classList.add(EhAnchorNavigationViewModel.HIDE_CLASSNAME);
      }
    }
  }
}