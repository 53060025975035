namespace eh {
    /**
     * Controller to manage show more/less in accordion. 
     * `EHShowMoreController`
     */
    export class EhAccordionController {
        static init($base: JQuery<HTMLElement>): void {
            $base.selfOrFind(`.${ EhAccordionController.SHOW_MORE_CLASSNAME }`).each((index:number, element:HTMLElement) => {
                new EhAccordionController(element);
            });    
        }

        public static SHOW_MORE_CLASSNAME: string = 'ehel-show-more';

        public static SHOW_MORE_BUTTON_CLASSNAME: string = 'ehel-show-more--button';
        public static SHOW_LESS_BUTTON_CLASSNAME: string = 'ehel-show-less--button';

        public static CLOSED_CLASSNAME: string = 'ehel-show-more--closed';

        
        private el: HTMLElement | null | undefined;
        private buttonMore: NodeListOf<HTMLElement> | null | undefined;
        private buttonLess: NodeListOf<HTMLElement> | null | undefined;

        private vm: EhAccordionViewModel;

        constructor(
            private readonly base: HTMLElement | null,
        ) {

            this.el = this.base;
            this.buttonMore = this.el?.querySelectorAll(`.${EhAccordionController.SHOW_MORE_BUTTON_CLASSNAME}`);
            this.buttonLess = this.el?.querySelectorAll(`.${EhAccordionController.SHOW_LESS_BUTTON_CLASSNAME}`);

            if (!this.buttonMore || !this.buttonLess || !this.el ) {
                //throw new Error(`Missing required element`);
                return;
            }

            this.vm = new EhAccordionViewModel(
                this.el,
                this.buttonMore,
                this.buttonLess
            );
            
            this.init();
        }

        private init(): void {
            this.registerControls();
        }

        private registerControls(): void {
          this.buttonMore?.forEach(x => {
            x.addEventListener('click', this.onButtonMoreClicked);
          });
          this.buttonLess?.forEach(x => {
              x.addEventListener('click', this.onButtonLessClicked);
          });
        }

        private onButtonMoreClicked = (e: MouseEvent | TouchEvent): void => {
            e.preventDefault();
            this.vm.showMore();
        }

        private onButtonLessClicked = (e: MouseEvent | TouchEvent): void => {
            e.preventDefault();
            this.vm.showLess();
        }
    }


    class EhAccordionViewModel {

        private static HIDE_CLASSNAME: string = 'ehtw-hidden';

        constructor(
            private showMoreElement: HTMLElement,
            private buttonMore: NodeListOf<HTMLElement>,
            private buttonLess: NodeListOf<HTMLElement>,
            ) {
            this.init();
        }


        private init(): void {
        }
    
        public showMore() {
            this.showMoreElement.classList.remove(EhAccordionController.CLOSED_CLASSNAME);
            this.buttonMore?.forEach(x => {
              x.classList.add(EhAccordionViewModel.HIDE_CLASSNAME);
            });
            this.buttonLess?.forEach(x => {
                x.classList.remove(EhAccordionViewModel.HIDE_CLASSNAME);
            });
          }

        public showLess() {
            this.showMoreElement.classList.add(EhAccordionController.CLOSED_CLASSNAME);
            this.buttonMore?.forEach(x => {
              x.classList.remove(EhAccordionViewModel.HIDE_CLASSNAME);
            });
            this.buttonLess?.forEach(x => {
                x.classList.add(EhAccordionViewModel.HIDE_CLASSNAME);
            });
          }
            
    }

}