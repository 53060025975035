namespace eh {

  /**
   * Controller to manage anchor-navigation behaviour. 
   */
  export class EhProductTeaserLinkTabController {

      public static ANCHOR_NAV_CLASSNAME: string = 'ehel-product-navigation';
      public static ANCHOR_NAV_NAVIGATION_CLASSNAME: string = 'ehel-product-navigation--navigation';
      public static ANCHOR_NAV_NAVIGATION_ITEM_CLASSNAME: string = 'ehel-button';

      public static ANCHOR_NAV_PREVIOUS_BTN_CLASSNAME: string = 'ehel-product-navigation--prev-btn';
      public static ANCHOR_NAV_NEXT_BTN_CLASSNAME: string = 'ehel-product-navigation--next-btn';

      private navigation: HTMLElement | null | undefined;
      private navigationItems: NodeListOf<HTMLElement> | undefined;

      private previousBtn: HTMLElement | null | undefined;
      private nextBtn: HTMLElement | null | undefined;
      private vm: EhAnchorNavigationViewModel;

      static init($base: JQuery<HTMLElement>): void {
        $(`.${EhProductTeaserLinkTabController.ANCHOR_NAV_CLASSNAME}`, $base).each((index: number, element: HTMLElement) => {
          new EhProductTeaserLinkTabController(element);
        });
      }

      constructor(
          private readonly el: HTMLElement | null,
      ) {
          this.navigation = this.el?.querySelector(`.${EhProductTeaserLinkTabController.ANCHOR_NAV_NAVIGATION_CLASSNAME}`);
          this.navigationItems = this.navigation?.querySelectorAll(`.${EhProductTeaserLinkTabController.ANCHOR_NAV_NAVIGATION_ITEM_CLASSNAME}`);
          this.previousBtn = this.el?.querySelector(`.${EhProductTeaserLinkTabController.ANCHOR_NAV_PREVIOUS_BTN_CLASSNAME}`);
          this.nextBtn = this.el?.querySelector(`.${EhProductTeaserLinkTabController.ANCHOR_NAV_NEXT_BTN_CLASSNAME}`);
          
          if (!this.navigation) {
              throw new Error(`Missing required element`);
          }

          this.vm = new EhAnchorNavigationViewModel(
              this.navigation, 
              this.navigationItems,
              this.previousBtn,
              this.nextBtn
          );
          
          if (!this.vm.isDisabled) {
              this.init();
          }
          
      }

      private init(): void {
          this.registerControls();
      }

      private registerControls(): void {
          this.previousBtn?.addEventListener('click', this.onPreviousClicked);
          this.nextBtn?.addEventListener('click', this.onNextClicked);
          this.navigationItems?.forEach((item: HTMLElement): void => {
              item.addEventListener('click', this.onItemClicked);
          });
      }

      private onItemClicked = (e: MouseEvent | TouchEvent): void => {
          e.preventDefault();
          this.vm.setCurrentItem(e.currentTarget as HTMLElement);
      }

      private onPreviousClicked = (e: MouseEvent | TouchEvent): void => {
          e.preventDefault();
          this.vm.previous();
      }

      private onNextClicked = (e: MouseEvent | TouchEvent): void => {
          e.preventDefault();
          this.vm.next();
      }

  }

  interface IScrollerItem {
      idx: number;
      link: HTMLElement;
      target: HTMLElement;
      thresholdBegin: number;
      thresholdEnd: number;
  }

  class EhAnchorNavigationViewModel {

      public isDisabled: boolean = false;

      private static ACTIVE_CLASSNAME: string = 'is-active';
      private static DISABLED_CLASSNAME: string = 'is-disabled';
      private static HIDE_CLASSNAME: string = 'ehtw-hidden';
      private static FLEX_CLASSNAME: string = 'ehtw-flex';
      private static TRANSITION_EASING_LINEAR: string = 'linear';
      private static TRANSITION_EASING_SWING: string = 'swing';
      private static TRANSITION_DURATION: number = 400;
      private static MIN_AMOUNT_SCROLL_ITEMS: number = 2;
      private _currentItem: IScrollerItem | undefined;
      private _scrollerItems: IScrollerItem[] = [];
      private targetScrollPosLeft: number = 0;
      private navigationHeight: number = 0;

      constructor(
          private scroller: HTMLElement,
          private items: NodeListOf<HTMLElement> | undefined,
          private previousButton: HTMLElement | null | undefined,
          private nextButton: HTMLElement | null | undefined,
      ) {

        this.init();
          // setTimeout(() => {
          //     this.init();
          // }, 2000);
          
      }

      public previous(): void {
          if (!this._currentItem) {
              return this.setCurrentItem(this.getItemByIdx(0).link);
          }
          const targetIndex: number = Math.max(0, this._currentItem.idx - 1)
          const previousItem: IScrollerItem = this.getItemByIdx(targetIndex);
          this.setCurrentItem(previousItem.link);
          //$(':root').trigger(TAB_EVENTS.TAB_CHANGE, item);
        }
      
      public next(): void {
          if (!this._currentItem) {
              return this.setCurrentItem(this.getItemByIdx(0).link);
          }
          const targetIndex: number = Math.min(this._scrollerItems.length - 1, this._currentItem.idx + 1);
          const nextItem: IScrollerItem = this.getItemByIdx(targetIndex);
          this.setCurrentItem(nextItem.link);
          //$(':root').trigger(TAB_EVENTS.TAB_CHANGE, item);
      }

      public setCurrentItem(item: HTMLElement | undefined, triggerJumpToAnchor: boolean = true): void {

          if (this._currentItem) {
              this._currentItem.link.classList.remove(EhAnchorNavigationViewModel.ACTIVE_CLASSNAME);
              this._currentItem?.target.classList.remove(EhAnchorNavigationViewModel.ACTIVE_CLASSNAME);
              this._currentItem.link.blur();
          }

          this._currentItem = this.getItemByEl(item);
          this._currentItem?.link.classList.add(EhAnchorNavigationViewModel.ACTIVE_CLASSNAME);
          this._currentItem?.target.classList.add(EhAnchorNavigationViewModel.ACTIVE_CLASSNAME);
          this.alignItemToViewport(this._currentItem);
      }

      private init(): void {
          this.navigationHeight = this.scroller.clientHeight;
          this.invalidateItems();
          this.setCurrentItem(this.getItemByIdx(0).link);
          
          if (this._scrollerItems.length < EhAnchorNavigationViewModel.MIN_AMOUNT_SCROLL_ITEMS) {
              this.isDisabled = true;
              // this.scroller.classList.add(EhAnchorNavigationViewModel.HIDE_CLASSNAME);
              this._scrollerItems = [];
              return;
          }

          window.addEventListener('resize', eh.debounce(this.invalidate, 40));
          const initialSelection: HTMLElement | null = this.scroller?.querySelector(`.${EhAnchorNavigationViewModel.ACTIVE_CLASSNAME}`) as HTMLElement;
          if (initialSelection) {
              this.setCurrentItem(initialSelection);
          }
          this.invalidateControls();
          this.scroller.addEventListener('scroll', eh.debounce(this.onNavigationScrollChange, 40), {passive: true});
      }

      private onNavigationScrollChange = (): void => {
          this.targetScrollPosLeft = this.scroller.scrollLeft;
          this.invalidateControls();
      };

      private alignItemToViewport(item: IScrollerItem | undefined): void {
          if (!item) {
              return;
          }
          const leftOffset: number = item.idx > 0 ? this.navigationHeight : 0;
          const rightOffset: number = item.idx < this._scrollerItems.length -1 ? this.navigationHeight : 0;
          
          const scrollerPos: number = this.scroller.scrollLeft;
          let clippingLeft: number = item.link.offsetLeft - leftOffset - scrollerPos;
          let clippingRight: number = item.link.offsetLeft + item.link.clientWidth - this.scroller.clientWidth + rightOffset - scrollerPos;

          if (clippingLeft < 0) {
              this.targetScrollPosLeft = scrollerPos + Math.min(0, clippingLeft);
          }
          if (clippingRight > 0) {
              this.targetScrollPosLeft = scrollerPos + Math.max(0, clippingRight);
          }

          if (this.scroller.scrollLeft !== this.targetScrollPosLeft) {
              $(this.scroller).animate({
                  scrollLeft: this.targetScrollPosLeft
              },
              EhAnchorNavigationViewModel.TRANSITION_DURATION,
              EhAnchorNavigationViewModel.TRANSITION_EASING_LINEAR);
          }

      }

      private animateToAnchor(item: IScrollerItem | undefined): void {
          const el: Element | null = document.scrollingElement;
          if (!el ) {
              return;
          }
          $(el).animate({
              scrollTop: item?.thresholdBegin
          },
          EhAnchorNavigationViewModel.TRANSITION_DURATION,
          EhAnchorNavigationViewModel.TRANSITION_EASING_SWING);
      }

      private getItemByEl(el: HTMLElement | undefined): IScrollerItem | undefined {
          return this._scrollerItems.find(si => si.link === el);
      }

      private getItemByIdx(idx: number): IScrollerItem {
          if (idx < 0 || idx > this._scrollerItems.length -1) {
              throw new Error(`Out of bounds. Failed to invalidate controls`);
          }
          return this._scrollerItems[idx];
      }

      private getItemByOffset(offset: number): IScrollerItem | undefined {
          return this._scrollerItems.find(i => {
              return i.thresholdBegin <= offset 
                    && i.thresholdEnd > offset;
          });
      }

      private invalidate = (): void => {
          this.invalidateControls();
          this.invalidateItems();
      }

      private invalidateItems = (): void => {
          this._scrollerItems = [];
          let idx: number = 0;
          this.items?.forEach((value: HTMLElement): void => {
              const targetKey: string | null = value.getAttribute('href') || '';
              const target: HTMLElement | null = document.getElementById(targetKey.replace(/[#!]/g, ''));
              const isDisabled: boolean = value.classList.contains(EhAnchorNavigationViewModel.DISABLED_CLASSNAME);
              if (!target || isDisabled) {
                  return;
              }
              const topPos: number = eh.offsetTopToBody(target) - this.navigationHeight;
              this._scrollerItems.push({
                  idx: idx++,
                  link: value,
                  target: target,
                  thresholdBegin: topPos,
                  thresholdEnd: topPos + target.getBoundingClientRect().height,
              });
          });
      }

      private invalidateControls = (): void => {
          const hideClass: string = EhAnchorNavigationViewModel.HIDE_CLASSNAME;

          // no active overflow
          if (this.scroller.scrollWidth === this.scroller.clientWidth) {
              this.previousButton?.classList.add(hideClass);
              this.nextButton?.classList.add(hideClass);
              return;
          }
          
          // handle previous button ctrl
          if (this.targetScrollPosLeft > 0) {
              this.previousButton?.classList.remove(hideClass);
          } else {
              this.previousButton?.classList.add(hideClass);
          }
          
          // handle next button ctrl
          const scrollerDelta: number = this.scroller.scrollWidth - this.scroller.clientWidth - 1;
          if (this.targetScrollPosLeft < scrollerDelta) {
              this.nextButton?.classList.remove(hideClass);
          } else {
              this.nextButton?.classList.add(hideClass);
          }

      }

    }
}